import { css } from "styled-components"

import "./fonts.css"
import "./normalize.css"

import { FONTS, TRANSITION_DURATION_MS } from "./constants"

export default css`
  body {
    font-family: ${FONTS.text};
    transition: background-color ${TRANSITION_DURATION_MS}ms ease-in-out;
    margin: 0;
  }
  h1 {
    font-family: ${FONTS.title};
    font-size: 2.5em;
  }
  h5 {
    font-weight: normal;
  }
  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
  }
  a {
    color: inherit;
  }
`
