export const COLORS = {
  // Primary:
  white: "#ffffff",
  sherpa: "#00414b", // "Sherpa blue" rgb(0,65,75)
  pink: "#ff7494", // "Tickle Me Pink" rgb(255,116,149)
  lightGreen: "#d9ffd4", // "Honeydew" rgb(217,255,212)
  // Secondary:
  lightPink: "#FFD9E5", // "Misty Rose" rgb(255,217,229)
  lightBlue: "#C2F4F7", // "Pale Turquoise" rgb(194,244,247)
  green: "#51C086", // "Emerald" rgb(81,192,134)
  blue: "#6ED2DB", // "Spray" rgb(110,210,219)
  // transparent:
  translucentSherpa: "rgba(0, 65, 75, 0.5)",
  translucentWhite: "rgba(255, 255, 255, 0.5)",
}

export const LAYOUT = {
  maxWidth: 1200,
  margin: 20,
  column: 900,
  headerExpanded: 110,
  headerCollapsed: 60,
}

export const FONTS = {
  title: "Cambria, Georgia, Times, serif",
  text: "Verdana, Tahoma, sans-serif",
}

export const TRANSITION_DURATION_MS = 200
